angular.module('auth')
  .constant('USER_INFO_PATH', 'userinfo')
  .service('UserService', function ($http, BASE_PATH, USER_INFO_PATH, userInfo, $log,$rootScope) {
    var factory = {};
    factory.setInstallation=function(installationId){
        factory.selectedInstallationId=installationId;
        $rootScope.$emit('selectedInstallationChanged');
    };
    factory.getSelectedInstallation=function(){
        return factory.selectedInstallationId;
    };
    factory.getUserInfo = function () {
      return $http.get(BASE_PATH.API_URL + USER_INFO_PATH).then(function (response) {
        $log.info(response);
        userInfo.permissions=response.data.userInfo.permissions;
          userInfo.groupSettings=response.data.userInfo.groupSettings||{};
          userInfo.moduleByInstallation=response.data.userInfo.moduleByInstallation||{};
          Object.keys(response.data.permissions||userInfo.permissions).forEach(function (key) {
              userInfo.permissions[key] = userInfo.permissions[key] || [];
          });
          factory.getHistoryFilter();
        return userInfo.userInfo;
      });
    };
    factory.getHistoryFilter=function(){
      $http.get(BASE_PATH.API_URL+'user/eventToHide').then(function (result) {
         userInfo.eventToHide=result.data||{};
      });
    };
    factory.eventCodeShouldBeHidden=function(eventCode,installationId){
      return (userInfo.eventToHide[installationId]||[]).indexOf(eventCode)>=0;
    };
      factory.mapCanBeZoomed = function(installationId){
          var mapCanBeZoomed = factory.getGroupSettingsByInstallationId(installationId).zoom_map_enabled;
          if(angular.isUndefined(mapCanBeZoomed) || mapCanBeZoomed){
              return true;
          }
          return mapCanBeZoomed;
      };
      factory.mapCanBePanned = function(installationId){
          var mapCanBePanned = factory.getGroupSettingsByInstallationId(installationId).pan_map_enabled;
          if(angular.isUndefined(mapCanBePanned) || mapCanBePanned){
              return true;
          }
          return mapCanBePanned;
      };
      factory.showMapNavigator = function(installationId){
          var showMapNavigator = factory.getGroupSettingsByInstallationId(installationId).show_map_navigator;
          if(angular.isUndefined(showMapNavigator) || showMapNavigator){
              return true;
          }
          return showMapNavigator;
      };
    factory.getGroupIdByInstallationId=function(installationId){
        return factory.getGroupSettingsByInstallationId(installationId).groupId;
    };
    factory.getGroupSettingsByInstallationId=function (installationId) {
      return userInfo.groupSettings[installationId]||{};
    };
    factory.getUserManagerCompanyId = function () {
      return userInfo.userInfo.company;
    };
    factory.getUserCompanyDescription = function () {
        /** @namespace userInfo.userInfo.companyname */
        return userInfo.userInfo.companyname;
    };
    factory.getUserUsername = function () {
      return userInfo.userInfo.username;
    };
    factory.getUserToken = function () {
        /** @namespace localStorage.ba_t */
        return localStorage.ba_t;
    };
    factory.getPermissionByInstallationId = function (installationId) {
      return userInfo.permissions[installationId] || [];
    };
    factory.enabledModuleByInstallation = function (installationId) {
      return userInfo.moduleByInstallation[installationId] || [];
    };
    factory.isAdmin=function () {
        /** @namespace userInfo.userInfo.useradmin */
        return userInfo.userInfo.useradmin;
    };
    factory.isCustomer = function () {
        /** @namespace userInfo.userInfo.is_customer */
        return userInfo.userInfo.is_customer;
    };
    factory.isGlobalAdmin = function () {
      return userInfo.userInfo.isadmin===true;
    };
    factory.getLoggedUserId=function () {
        /** @namespace userInfo.userInfo.iduser */
        return userInfo.userInfo.iduser;
    };
    factory.deleteUser=function (userIdToDelete) {
      return $http.delete(BASE_PATH.API_URL+'employee/'+ userIdToDelete + '/' + factory.getLoggedUserId());
    };
    factory.getInitialMapByInstallationId=function(installationId){
        return userInfo.groupSettings[installationId].initialMapSymbol;
    };
    factory.updateUser=function(user){
     $http.post(BASE_PATH.API_URL+'/user/'+user.userId,user);
    };

    function _init(){
        userInfo.permissions = userInfo.userInfo.permissions || {};
        userInfo.groupSettings=userInfo.userInfo.groupSettings||{};
        userInfo.moduleByInstallation=userInfo.userInfo.moduleByInstallation||{};
        Object.keys(userInfo.permissions).forEach(function (key) {
            userInfo.permissions[key] = userInfo.permissions[key] || [];
        });
        userInfo.eventToHide={};
        factory.getHistoryFilter();
    }
    _init();
    return factory;
  });
