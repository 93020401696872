angular.module('shared', ['ui.router', 'permission', 'permission.ui', 'ngAnimate', 'toastr', 'auth', 'maps'])
    .constant('TIME_SLOT_KEY', 'time-slot')
    .constant('PAGE_SIZE', 20)
    .filter('trustAsHtml', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    })
    .constant('HOME_PAGE_OPTIONS', [
        {name: 'Lista installazioni', stateName: 'installations', needInstallationId: true},
        {name: 'Lista Gruppi', stateName: 'groupList', needInstallationId: true},
        {name: 'Lista Clienti', stateName: 'customers'},
        {name: 'Lista Utenti', stateName: 'companymanagement'},
        {name: 'Lista Installatori', stateName: 'managers'},
        {name: 'Controllo accessi', stateName: 'access-control', needInstallationId: false, isMap: false, url: 'access-control'},
        {name: 'Staff management', stateName: 'staff-gui', needInstallationId: false, isMap: false, url: 'staff-gui'},
        {name: 'Suite - Mappe', stateName: 'bms2', needInstallationId: false, isMap: false, url: 'bms2'},
        {name: 'Suite - Query', stateName: 'queries', needInstallationId: false, isMap: false, url: 'queries'},
        {name: 'Suite - Com90', stateName: 'com90', needInstallationId: false, isMap: false, url: 'com90'}
    ])
    .constant('URLS_WITHOUT_ALERT', ['/#!/videocloud/'])
    .filter('initialPageNameFromUrl', function (HOME_PAGE_OPTIONS, $state) {
        return function (input) {
            input = input || '';
            var found = {name: input};
            var optionsMapped;
            angular.forEach($state.get(), function (state) {
                // eslint-disable-next-line angular/no-private-call
                var privatePortion = state.$$state();
                if (privatePortion && privatePortion.url) {
                    var match = privatePortion.url.exec(input) || privatePortion.url.exec(input.replace('#!', ''));
                    if (match) {
                        found = state;
                    }
                }
            });
            optionsMapped = HOME_PAGE_OPTIONS.filter(function (item) {
                return item.stateName === found.name;
            })[0];
            return optionsMapped ? found.name = optionsMapped.name : found.name;

        };
    })
    .run(function (keepAlive, soundService, socketService,$timeout,toastr, $window, URLS_WITHOUT_ALERT) {
    var tokenInfo=jwt_decode(localStorage.ba_t);
        // check if token has expire

        var noAlert = false;
        for(var i=0; i<URLS_WITHOUT_ALERT.length;i++) {
            if($window.location.href.includes(URLS_WITHOUT_ALERT[i])) noAlert = true;
        }
        if(noAlert) return;
        if (tokenInfo.exp == null) {
            return;
        }
        var expire=new Date(tokenInfo.exp*1000);
        var now=new Date();
        var firstExpireWarning=new Date(expire);
        const firstTimeout = 40;
        firstExpireWarning.setMinutes(firstExpireWarning.getMinutes()-firstTimeout);
        var firstExpireWarningDelta=firstExpireWarning-now;

        var toastrCfg={
            timeOut: 0,
            extendedTimeOut: 0,
            closeIcon: false,
            allowHtml: true,
            closeButton: true,
            tapToDismiss: true,
            toastClass: 'toast no-opacity',
            closeHtml: '<button><i class="fa fa-times-circle"></i></button>'
        };

        const message = 'Per motivi di sicurezza la sessione terminerà tra ';

        if(firstExpireWarningDelta > 0) {
            var firstWarningMsg=message + firstTimeout + ' minuti' ;
            $timeout(function(){
                toastr.info(firstWarningMsg, 'Attenzione', toastrCfg);
            },firstExpireWarningDelta);    
        }
        const secondTimeout = 20;
        var secondExpireWarning=new Date(expire);
        secondExpireWarning.setMinutes(secondExpireWarning.getMinutes()-secondTimeout);
        var secondExpireWarningDelta=secondExpireWarning-now;

        if(secondExpireWarningDelta > 0) {
            var secondWarningMSg=message + secondTimeout + ' minuti' ;
            $timeout(function(){
                toastr.info(secondWarningMSg, 'Attenzione', toastrCfg);
            },secondExpireWarningDelta);
        }

        if(secondExpireWarningDelta <= 0) {
            var tooLateExpireWarning = new Date(expire) - now;
            if(tooLateExpireWarning > 0 && tooLateExpireWarning <= 120000) {
                $timeout(function() {
                    toastr.info(message + Math.floor(tooLateExpireWarning / 60000) + ' minuti', 'Attenzione', toastrCfg);
                },1);    
            }
        }
    });
