angular.module('shared').component('headerComponent', {
  templateUrl: 'app/shared/header/headerComponent.tpl.html',
  controller: function($rootScope,userInfo,$log,$uibModal,$window,UserService){
    var vm=this;
    vm.installationId=UserService.getSelectedInstallation();
    vm.eventListner=$rootScope.$on('selectedInstallationChanged', function(){
          vm.installationId=UserService.getSelectedInstallation();
      });
    vm.showUserInfo=function () {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/auth/userInfoDetail/userInfoPopup.html',
        controller: 'UserInfoInstanceCtrl',
        resolve: {
          user: function () {
            return vm.userInfo.userInfo;
          }
        }
      });
    };
    //FIXME
    vm.logout = function () {
      delete (localStorage.ba_t);
      $window.location.href = userInfo.logoutUrl;
    };
    vm.goToChangePwd = function () {
      delete (localStorage.ba_t);
      $window.location.href = userInfo.changePwdUrl;
    };
    vm.$onInit=function(){
      vm.userInfo=userInfo;
    };
  }
});
