/* eslint-disable angular/definedundefined,angular/controller-as,angular/controller-as-vm,angular/timeout-service,angular/log */

angular.module('plants')
  .controller('PlantConfigUploadCtrl', function ($log, BASE_PATH, FileUploader, $uibModalInstance, $scope, installationId) {
    var $ctrl = this;

    if (installationId === undefined){
      $uibModalInstance.dismiss('cancel');
      $log.error('error in map uploader instance - no installationid');
    }
    $scope.ok = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    var uploader = $scope.uploader = new FileUploader({
      queueLimit : 1,
      url: BASE_PATH.API_URL+'installation/'+installationId+'/plantconfig',
      headers : {
        'x-access-token': localStorage.ba_t
      }
    });

  // FILTERS

  // a sync filter
    uploader.filters.push({
      name: 'syncFilter',
      fn: function(item /*{File|FileLikeObject}*/, options) {
        return this.queue.length < 10;
      }
    });

  // an async filter
    uploader.filters.push({
      name: 'asyncFilter',
      fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
        setTimeout(deferred.resolve, 1e3);
      }
    });

  // CALLBACKS

  uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
    if ($scope.uploader.queue.length > 0){
      //TODO messaggio di errore nel popup
      alert('Rimuovere il file precaricato e riprovare.')
    }
    console.info('onWhenAddingFileFailed', item, filter, options);
  };
    uploader.onAfterAddingFile = function(fileItem) {
      console.info('onAfterAddingFile', fileItem);
    };
    uploader.onAfterAddingAll = function(addedFileItems) {
      console.info('onAfterAddingAll', addedFileItems);
    };
    uploader.onBeforeUploadItem = function(item) {
      console.info('onBeforeUploadItem', item);
    };
    uploader.onProgressItem = function(fileItem, progress) {
      console.info('onProgressItem', fileItem, progress);
    };
    uploader.onProgressAll = function(progress) {
      console.info('onProgressAll', progress);
    };
    uploader.onSuccessItem = function(fileItem, response, status, headers) {
      console.info('onSuccessItem', fileItem, response, status, headers);
    };
    uploader.onErrorItem = function(fileItem, response, status, headers) {
      console.info('onErrorItem', fileItem, response, status, headers);
    };
    uploader.onCancelItem = function(fileItem, response, status, headers) {
      console.info('onCancelItem', fileItem, response, status, headers);
    };
    uploader.onCompleteItem = function(fileItem, response, status, headers) {
      console.info('onCompleteItem', fileItem, response, status, headers);
    };
    uploader.onCompleteAll = function() {
      console.info('onCompleteAll');
    };
  });
