/* eslint-disable */
angular.module('managers')
  .controller('NewManagerModalCtrl', function (FileUploader, $uibModal, fileReader, modalService, $http, BASE_PATH, $uibModalInstance , type, UserService, $scope,Manager,blockUI) {
    var $ctrl = this;
    $scope.imageBasePath = BASE_PATH.MEDIA_PATH;
    var uploader = $scope.uploader = new FileUploader({
      queueLimit : 1,
      url: BASE_PATH.API_URL+'uploadlogo',
      headers : {
        'x-access-token': localStorage.ba_t
      }
    });
    // FILTERS
    uploader.filters.push({
      name: 'imageFilter',
      fn: function(item /*{File|FileLikeObject}*/) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });

    // CALLBACKS
    uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
      if ($scope.uploader.queue.length > 0){
        //TODO messaggio di errore nel popup
        alert('Rimuovere il file precaricato e riprovare.')
      }
      console.info('onWhenAddingFileFailed', item, filter, options);
    };
    uploader.onAfterAddingFile = function(fileItem) {
      console.info('onAfterAddingFile', fileItem);
    };
    uploader.onAfterAddingAll = function(addedFileItems) {
      console.info('onAfterAddingAll', addedFileItems);
    };
    uploader.onBeforeUploadItem = function(item) {
      console.info('onBeforeUploadItem', item);
    };
    uploader.onProgressItem = function(fileItem, progress) {
      console.info('onProgressItem', fileItem, progress);
    };
    uploader.onProgressAll = function(progress) {
      console.info('onProgressAll', progress);
    };
    uploader.onSuccessItem = function(fileItem, response, status, headers) {
      console.info('onSuccessItem', fileItem, response, status, headers);
    };
    uploader.onErrorItem = function(fileItem, response, status, headers) {
      console.info('onErrorItem', fileItem, response, status, headers);
    };
    uploader.onCancelItem = function(fileItem, response, status, headers) {
      console.info('onCancelItem', fileItem, response, status, headers);
    };
    uploader.onCompleteItem = function(fileItem, response, status, headers) {
      console.info('onCompleteItem', fileItem, response, status, headers);
      if (status === 200){
        $scope.manager.customParameters.logo = response.logoId;
      }
    };
    uploader.onCompleteAll = function() {
      console.info('onCompleteAll');
    };
    $scope.type = type;
    $scope.manager =new Manager({"customParameters" : {'invitation' : 'notSent'}});

    $scope.getFile = function () {
      $scope.progress = 0;
      fileReader.readAsDataUrl($scope.file, $scope)
        .then(function(result) {
          $scope.imageSrc = result;
          if (angular.isUndefined($scope.manager.customParameters)){
            $scope.manager.customParameters = {};
          }
          $scope.manager.customParameters.logo = result;
        });
    };

    $scope.$on("fileProgress", function(e, progress) {
      $scope.progress = progress.loaded / progress.total;
    });

    $scope.ok = function () {
      blockUI.start();
      $scope.manager.$save().then(function(modifiedCustomer){
              $uibModalInstance.close(modifiedCustomer);
              blockUI.stop();
            }, function(error){
              if(error.data.includes("ALREADY_EXIST")){
                var mailAdmin = document.getElementById("adminEmail").value;
                $scope.errorMessage = { 'errorObj' : "L'email " + mailAdmin + " è già stata usata nel sistema.", 'type' : 'ServerError'};
              }else{
                $scope.errorMessage = { 'errorObj' : error.data.error, 'type' : 'ServerError'};
              }
              blockUI.stop();
            });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
